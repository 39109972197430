/**
 * @ngdoc directive
 * @module uploadModule
 * @name fileUpload
 * @param file
 *          This is the File to be uploaded
 * @param uploadUrl
 *          This is the Rest service to upload the File and Form Data
 * @param formData
 *          This is the Form Data to be uploaded along with File data
 * @description This directive is used to upload Multipart File/Form Data from
 *              UI to Rest Services This can send the uploaded file into server
 *              along with Form Data The required FormData need to be passed as
 *              parameter This directive simply creates the scope variable for
 *              the file name specified
 */
(function () {
  'use strict';
  angular.module('downloadModule', []);

  angular.module('downloadModule').service('fileDownloadSvc', [
    '$http',
    '$log',
    function ($http, $log) {
      this.downloadFile = function (
        downloadUrl,
        { responseType, method, headers = {} } = {}
      ) {
        return $http({
          url: downloadUrl,
          method: method || 'GET',
          headers: {
            'Content-Type': 'application/json, text/plain, */*',
            ...headers,
          },
          responseType: responseType || 'arraybuffer',
        }).then(
          function (response) {
            $log.debug('Download is Successful');
            let contentDispositionHeader = response.headers(
              'Content-Disposition'
            );

            let filename;
            if (contentDispositionHeader) {
              filename = contentDispositionHeader.split('=')[1];
              if (contentDispositionHeader.split('"')[1]) {
                filename = contentDispositionHeader.split('"')[1];
              }
            } else {
              filename = new Date().toString();
            }

            let myBuffer = new Uint8Array(response.data);
            let file = new Blob([myBuffer], {
              type: 'application/json, text/plain, */*',
            });
            return { file: file, fileName: filename };
          },
          function () {
            $log.debug('Download failed');
            throw new Error('Download failed');
          }
        );
      };
    },
  ]);

  angular.module('downloadModule').service('dwtFileDownloadSvc', [
    'fileDownloadSvc',
    '$sessionStorage',
    function (fileDownloadService) {
      this.downloadFile = (url) =>
        fileDownloadService.downloadFile(url, {});
    },
  ]);
})();
